<template>
  <div>
    <div class="time-frame-container">
      <SelectMenu
        v-if="canChangeChartDateOrExport(roleLoggedUser)"
        :options="timeframeOptions"
        :selected="selectedTimeframe"
        @menuItemChanged="handleTimeframeSelection"
      />
    </div>
    <div class="chart-container">
      <canvas id="daily-active-hours"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";
import { dailyActiveHoursChartConfig } from "../../chart/chartConfig.js";
import SelectMenu from "../Shared/SelectMenu.vue";

export default {
  data() {
    const timeframeOptions = {
      weeks_2: {
        value: "weeks_2",
        label: "Last 2 Weeks",
      },
      weeks_4: {
        value: "weeks_4",
        label: "Last 4 Weeks",
      },
      weeks_6: {
        value: "weeks_6",
        label: "Last 6 Weeks",
      },
      weeks_8: {
        value: "weeks_8",
        label: "Last 8 Weeks",
      },
    };
    return {
      labels: [],
      activeHoursData: [],
      selectedTimeframe: timeframeOptions.weeks_8,
      movingAvgData: [],
      timeframeOptions,
      chart: null,
    };
  },
  components: { SelectMenu },
  computed: {
    dailyActiveHours() {
      return this.$store.state.detailWaterPoint.dailyActiveHours;
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    selectedStartTimeFromNow() {
      let ret = moment();
      switch (this.selectedTimeframe.value) {
        case this.timeframeOptions.weeks_2.value:
          ret.subtract(2, "week");
          break;
        case this.timeframeOptions.weeks_4.value:
          ret.subtract(4, "week");
          break;
        case this.timeframeOptions.weeks_6.value:
          ret.subtract(6, "week");
          break;
        case this.timeframeOptions.weeks_8.value:
          ret.subtract(8, "week");
          break;
        default:
          break;
      }
      return ret;
    },
  },
  methods: {
    canChangeChartDateOrExport(role) {
      return role === "SuperAdmin" || role === "Admin" || role === "ViewEdit";
    },
    filterAndOrderChartData(timeFrame) {
      const dailyActiveHoursFiltered = this.dailyActiveHours.filter((item) => {
        // only keep data within the given time frame
        return moment(item.dateDay) >= timeFrame;
      });
      // reverse data to be ordered from old to new
      const dailyActiveHoursReversed = dailyActiveHoursFiltered
        .slice()
        .reverse();
      this.labels = dailyActiveHoursReversed.map((item) =>
        moment(item.dateDay).format("D MMM YYYY").toUpperCase(),
      );
      this.activeHoursData = dailyActiveHoursReversed.map(
        (item) => item.activeHours,
      );
      this.movingAvgData = dailyActiveHoursReversed.map(
        (item) => item.movingAvg,
      );
    },
    updateChart() {
      this.chart.data.datasets[0].data = this.activeHoursData;
      this.chart.data.datasets[1].data = this.movingAvgData;
      this.chart.data.labels = this.labels;
      this.chart.options.scales.xAxes[0].ticks.maxTicksLimit = 10;
      this.chart.update();
    },
    handleTimeframeSelection(selectedItem) {
      this.selectedTimeframe = selectedItem;
      this.filterAndOrderChartData(this.selectedStartTimeFromNow);
      this.updateChart();
    },
  },
  async mounted() {
    if (!this.dailyActiveHours) {
      // the backend default the query to 1 year, which is plenty to show max 8 weeks of data
      await this.$store.dispatch(
        "getSensorDailyActiveHours",
        this.$route.params,
      );
    }
    this.filterAndOrderChartData(this.selectedStartTimeFromNow);
    this.chart = new Chart(
      document.getElementById("daily-active-hours").getContext("2d"),
      dailyActiveHoursChartConfig,
    );
    this.updateChart();
  },
};
</script>

<style scoped lang="scss">
.chart-container {
  height: 65vh;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    max-width: 25em;
    height: 25em;
  }
  canvas {
    position: relative;
    padding: 1.75em;
  }
}
.time-frame-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  padding: 1em;
  &-select {
    padding: 8px 10px;
    font-size: 14px;
    padding-right: 20px;
    border: 1px solid #e1e6ef;
    border-radius: 8px;
    background-color: white;
    font-family: var(--app-font);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
